import Vue from 'vue'
import { Message, MessageBox } from 'element-ui';
import i18n from '@/locales'
import { voiceRead, beep } from '@/helpers/utils/voiceMessages'
import router from "@/router"
import { printQualityPdf, printQualityPdfCustomPrinter,printHtmlInvoice } from '@/helpers/utils/print'
import printJS from 'print-js'

const state = () => ({
  barcodeType: 0, //0= masa, 1= ürün
  combineData: {},
  putToBox: { // koyulacak kutu 
    definition: '-'
  },
  currentParcel: {},
  productCount: 1,

  requestParcelBarcode: null,
  requestParcelRank: null,
  qualityControl: false,
  readedBarcodeCount: 0,
  selectedBarcode: '',

  customCargoPDFBase64: null,
  isShowCustomCargoPDF: false
})

// getters
const getters = {
  parcelList(state) {
    return state.combineData.parcels
  },
  carInformation(state) {
    return state.combineData.warehouseCar
  },
  tableInformation(state) {
    return {
      barcode: state.combineData.tableBarcode,
      definition: state.combineData.tableDefinition,
      id: state.combineData.tableID
    }
  },
  numbers(state) {
    return {
      totalOrderCount: state.combineData.totalOrderCount,
      totalProductCount: state.combineData.totalProductCount,
      pickedProductCount: state.combineData.pickedProductCount,
      missingProductCount: state.combineData.missingProductCount,
      combinedProductCount: state.combineData.combinedProductCount
    }
  }
}

// actions
const actions = {
  async getCombineData({ commit, dispatch }) {
    try {
      const response = await Vue.prototype.$axios.get('/api/table-order-combines')
      commit('setCombineData', response.data.model)
      commit('changeBarcodeType', 1)
      //BİRLEŞTİRME BİTTİYSE POPUP AÇ
      if (response.data.model.totalProductCount == response.data.model.combinedProductCount + response.data.model.missingProductCount) {
        dispatch('completeProccessAlert')
      }
    } catch (error) {
      // console.log(error)
    }
  },
  async setCombineTable({ dispatch }) {
    try {
      const response = await Vue.prototype.$axios.put('/api/table-order-combines/tables/' + this.state.app.barcode)
      await dispatch('getCombineData')
    } catch (error) {
      // console.log(error)
    }
  },
  async combineProduct({ state, commit, dispatch }) {
    try {
      const response = await Vue.prototype.$axios.post('/api/table-order-combines/assign-product', {
        barcode: this.getters['user/getSettings'].orderCombineSettings.multipleProductCombine ? state.selectedBarcode : this.state.app.barcode,
        piece: this.getters['user/getSettings'].orderCombineSettings.multipleProductCombine ? state.productCount : 1,
        setNo: state.combineData.warehouseCar.setNo,
        tableId: state.combineData.tableID
      })
      commit('setPlacedBox', response.data.model.parcel)
      commit('updateParcel', response.data.model.parcel)
      // console.log("ass", state.productCount)
      commit('setCombineProductIncrease', state.productCount)
      setTimeout(() => {
        voiceRead(response.data.model.parcel.definition)
      }, 0);

      console.log("d", this.getters['user/getSettings'].orderCombineSettings.multipleProductQualityControl)
      if (state.combineData.totalProductCount == state.combineData.combinedProductCount + state.combineData.missingProductCount && this.getters['user/getSettings'].orderCombineSettings.multipleProductQualityControl)
        await dispatch('completeQualityControl')

      //TEK ÜRÜN KALİTE KONTROL AÇIKSA VE TAMAMLANDIYSA KALİTE KONTROL YAP
      if (response.data.model.parcel.totalNumberOfProducts == 1 && response.data.model.parcel.totalNumberOfPreparedProducts == 1 && this.getters['user/getSettings'].urunToplamaAyar.masaTekUrunKaliteKontrol && this.getters['user/getSettings'].orderCombineSettings.multipleProductQualityControl == false) {
        await dispatch('completeQualityControl')
      }
      //BİRLEŞTİRME BİTTİYSE POPUP AÇ
      if (state.combineData.totalProductCount == state.combineData.combinedProductCount + state.combineData.missingProductCount) {
        dispatch('completeProccessAlert')
      }
    } catch (error) {
      beep('no')
      // console.log(error)
    }
  },

  //TEK ÜRÜNLÜ SİPARİŞ KALİTE KONTROL TAMAMLA
  async completeQualityControl({ state, dispatch }) {
    try {
      const products = await Vue.prototype.$axios.get(`/api/order/${state.currentParcel.orderIDList[0]}/products`)
      const response = await Vue.prototype.$axios.post(`/api/quality-controls/orders/${state.currentParcel.orderIDList[0]}/completed`, {
        desi: 0,
        giftPackage: false,
        boxID: state.currentParcel.id,
        products: [{
          productID: products.data[0].productID,
          orderProductID: products.data[0].orderProductID,
          piece: products.data[0].occurrencesPiece,
          missingPiece: products.data[0].missingPiece,
        }]
      })
      Message({ type: 'success', message: i18n.t('SiparisKaliteKontrolYapildi') })

      await dispatch('getCombineData')
      if (this.getters['user/getUser'].customPrinter) {
        printQualityPdfCustomPrinter(response.data.model, this.getters['user/getUser'].id)
      } else {

        let isCargoPDF = this.getters['user/getSettings'].kaliteKontrolAyar.showCustomCargoPdf && response.data.model.customCargoPDF !== null
        let isPrintOrderPdf = this.getters['user/getSettings'].kaliteKontrolAyar.printOrderPdf
        
        return new Promise(resolve => {
          if (response.data.model.cargoHtml != null) {
            printHtmlInvoice(response.data.model.cargoHtml)
          }
          if (response.data.model.orderHtml != null && isPrintOrderPdf) {
            printHtmlInvoice(response.data.model.orderHtml)
          }

          if (this.getters['user/getSettings'].kaliteKontrolAyar.printOrderPdf && response.data.model.invoicePDF !== null) {
            printJS({
              printable: response.data.model.invoicePDF,
              type: 'pdf',
              base64: true,
              onPrintDialogClose: function (event) {
                if (event === true && isCargoPDF) {
                  state.isShowCustomCargoPDF = true //iptal butonu
                  state.customCargoPDFBase64 = response.data.model.customCargoPDF
                  router.push({ name: 'SiparisBirlestirmePDF' })

                } else {
                  console.log("isCargoPDF", isCargoPDF)
                  if (isCargoPDF) {
                    console.log("deneme3")
                    state.isShowCustomCargoPDF = true //iptal butonu
                    state.customCargoPDFBase64 = response.data.model.customCargoPDF
                    router.push({ name: 'SiparisBirlestirmePDF' })
                  }
                }
              }
            });
          }

          else {
            console.log("else")
            if (isCargoPDF) {
              console.log("isCargoPDF else")
              printJS({
                printable: response.data.model.customCargoPDF,
                type: 'pdf',
                base64: true,
              });
            }
          }
          resolve();
        });

      }

    } catch (error) {
      // console.log(error)
    }
  },

  //EKSİK ÜRÜN KOLİSİNE ATAMA SÜREÇLERİ
  openAssignMissingBoxPopup({ dispatch, state }, qualityControl = false) {
    state.qualityControl = qualityControl
    MessageBox.prompt(i18n.t('EksikUrunKoliBarkodunuOkutunuz'), i18n.t('EksikKoli'), {
      confirmButtonText: i18n.t('Tamamla'),
      cancelButtonText: i18n.t('Vazgec'),
      inputPattern: /^.{2,}$/,
      inputErrorMessage: i18n.t('DoldurulmasiZorunluAlan')
    }).then(({ value }) => {
      state.requestParcelBarcode = value
      dispatch('openAssignMissingBoxRankPopup')
    }).catch(() => {
      //do nothing
      throw Error
    });
  },
  openAssignMissingBoxRankPopup({ state, dispatch }) {
    MessageBox.prompt(i18n.t('EksikUrunKoliSirasiGiriniz'), i18n.t('EksikKoli'), {
      confirmButtonText: i18n.t('Tamamla'),
      cancelButtonText: i18n.t('Vazgec'),
      inputPattern: /^.{1,}$/,
      inputErrorMessage: i18n.t('DoldurulmasiZorunluAlan')
    }).then(({ value }) => {
      state.requestParcelRank = value
      dispatch('assignMissingBox')
    }).catch(() => {
      //do nothing
      throw Error
    });
  },
  async assignMissingBox({ state, dispatch }) {
    try {
      const response = await Vue.prototype.$axios.put(`/api/table-order-combines/tables/${state.combineData.tableID || this.state.qualityControl.currentTable.id}/parcels/${state.currentParcel.id || this.state.qualityControl.currentParcel.id}`, {
        requestParcelBarcode: state.requestParcelBarcode,
        requestParcelRank: state.requestParcelRank
      })
      Message({ type: 'success', message: i18n.t('KoliEksikUrunKolisiOlarakIsaretlendi') })
      if (state.qualityControl) {
        //kalite kontrol sayfasından çalıştırıldıysa fonksiyon koliden çıkıp koli listesini tekrar çeker
        this.dispatch('qualityControl/leaveOrder')
        await this.dispatch('qualityControl/getUserTable')
      } else {
        await dispatch('getCombineData')
      }
    } catch (error) {
      throw error
    }
  },

  //ALERT
  completeProccessAlert() {
    MessageBox.confirm(i18n.t('KaliteKontroleYadaArabaBirakmayaGidebilirsiniz'), i18n.t('SiparisBirlestirmeTamamlandi'), {
      confirmButtonText: i18n.t('KaliteKontroleGit'),
      cancelButtonText: i18n.t('ToplamaArabalarinaGit'),
      type: 'success',
      center: true,
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
    }).then(async () => {
      router.push({ name: 'KaliteKontrol' })
    }).catch(() => {
      router.push({ name: 'ToplamaArabasi' })
    });
  }
}

// mutations
const mutations = {
  changeBarcodeType(state, payload) {
    state.barcodeType = payload
  },
  setCombineData(state, payload) {
    state.combineData = payload
  },
  setCurrentParcel(state, payload) {
    state.currentParcel = payload
  },
  setCombineProductIncrease(state, payload) {
    state.combineData.combinedProductCount += payload
  },
  updateParcel(state, payload) {
    let getIndex = state.combineData.parcels.findIndex(item => item.id == payload.id)
    state.combineData.parcels[getIndex] = payload
    state.currentParcel = payload
  },
  setPlacedBox(state, payload) {
    state.putToBox = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}